import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import errorStateSelector from '../recoil/selectors/ErrorStateSelector';
import AuthService from '../services/AuthService';
import BaseService from '../services/BaseService';

const useUnauthenticate = () => {
  const setError = useSetRecoilState(errorStateSelector);

  return useCallback(() => {
    return AuthService.logout().then(() => {
      setError(null);
      BaseService.unsetClientTenantDetails();
      BaseService.clearToken();
    });
  }, [setError]);
};

export default useUnauthenticate;
