/* eslint-disable @typescript-eslint/no-explicit-any */

import { HTMLProps, UIEvent } from 'react';

export const dataAttributeProps = (props: any) => {
  const newProps = {} as any;

  Object.keys(props).forEach((key) => {
    if (String(key).startsWith('data-')) {
      newProps[key] = props[key];
    }
  });

  return newProps;
};

export const ariaAttributeProps = (props: any) => {
  const newProps = {} as any;

  Object.keys(props).forEach((key) => {
    if (String(key).startsWith('aria-')) {
      newProps[key] = props[key];
    }
  });

  return newProps;
};

export const mouseAndKeyboardCallbackProps = (cb?: (e: UIEvent<HTMLElement>) => void) => {
  if (!cb) return {};

  return {
    onClick: (e) => {
      cb(e);
    },
    onKeyDown: (e) => {
      if ((e.target as HTMLElement)?.tagName === 'INPUT') return;

      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        cb(e);
      }
    },
  } as Pick<HTMLProps<HTMLElement>, 'onClick' | 'onKeyDown'>;
};
