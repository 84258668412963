import { useEffect, useState } from 'react';
import { ToastConfig, ToastType } from '../../contexts/ToastContext';
import useSlot from '../../hooks/useSlots';
import withSlot, { SlotDefinitions } from '../../wrappers/withSlot';
import XIcon from './icon/XIcon';
import { Heading, HeadingSize } from './text/Heading';

type ToastProps = {
  toast: ToastConfig;
  removeToast: (id: string) => void;
};

const Toast = withSlot<ToastProps, SlotDefinitions<['button']>>((props) => {
  const { toast, removeToast } = props;
  const { id, title, type, description, expiresInMs } = toast;
  const [animatingOut, setAnimatingOut] = useState(false);

  let borderColor;
  switch (type) {
    default:
    case ToastType.INFO:
      borderColor = 'border-semantic-5';
      break;
    case ToastType.ERROR:
      borderColor = 'border-semantic-2';
      break;
    case ToastType.SUCCESS:
      borderColor = 'border-semantic-1';
      break;
    case ToastType.WARNING:
      borderColor = 'border-semantic-3';
      break;
  }

  const onClose = () => {
    setAnimatingOut(true);
    setTimeout(() => {
      removeToast(id);
    }, 500);
  };

  useEffect(() => {
    if (!expiresInMs || expiresInMs <= 0) {
      return;
    }
    setTimeout(() => {
      setAnimatingOut(true);
    }, 750 + expiresInMs);
    setTimeout(
      () => {
        removeToast(id);
      },
      750 + expiresInMs + 300,
    );
  }, [id, removeToast, expiresInMs]);

  const button = useSlot(props, 'button');

  return (
    <div
      className={`${borderColor} rounded-tr-dpm-sm rounded-tl-dpm-sm relative w-full border-0 border-t-4 bg-white shadow-lg ${
        animatingOut ? 'md:animate-toast-out-top-right animate-toast-out-top-center' : 'md:animate-toast-in-top-right animate-toast-in-top-center'
      }`}
      data-cy="toaster"
    >
      <XIcon data-cy="toaster-close" className="absolute right-0 top-0 mr-1 mt-1 h-4 w-4" onClick={onClose} />
      <Heading size={HeadingSize.H6} className="pb-2 font-medium" data-cy="toaster-heading">
        {title}
      </Heading>
      <div data-cy="toaster-description">{description}</div>
      <div data-cy="toaster-button-slot" className="flex justify-end empty:hidden" onClick={onClose}>
        {button()}
      </div>
    </div>
  );
});

export default Toast;
