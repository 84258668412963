import { atom } from 'recoil';
import ErrorType from '../../models/Error';

export interface ErrorStateProps {
  type: (typeof ErrorType)[keyof typeof ErrorType] | null;
  showContactSupport?: boolean;
  showRefresh?: boolean;
  showGoBack?: boolean;
  goBackCallback?: () => void;
  pageUrl?: string;
}

const errorAtom = atom<ErrorStateProps | null>({
  key: 'errorState',
  default: null,
});

export { errorAtom };
