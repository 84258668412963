import { RefObject, createContext, useContext, useRef } from 'react';
import { FCWithChildren } from '../types/FCWithChildren';

type LayoutContextType = {
  leftNavRef: RefObject<HTMLDivElement>;
};

export const LayoutContext = createContext<LayoutContextType>(null!);
export const useLayout = (): LayoutContextType => useContext(LayoutContext);
export const LayoutProvider: FCWithChildren = (props) => {
  const { children } = props;
  const leftNavRef = useRef<HTMLDivElement>(null);
  return <LayoutContext.Provider value={{ leftNavRef: leftNavRef }}>{children}</LayoutContext.Provider>;
};
