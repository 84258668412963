import { Client } from '../models/Client';
import User from '../models/User';

class IntercomUtils {
  // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomboot-intercomsettings
  static initialize = (): void => {
    if (window.Intercom)
      window.Intercom('boot', {
        app_id: import.meta.env.VITE_INTERCOM_APP_ID as string,
        hide_default_launcher: true,
        alignment: 'right',
        horizontal_padding: 20,
        vertical_padding: 20,
      });
  };

  // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomupdate
  static setUserDetails = (profile?: User | null, client?: Client | null): void => {
    if (window.Intercom) {
      const details: Record<string, unknown> = {};
      if (profile) {
        details.name = `${profile.firstName} ${profile.lastName}`;
        details.email = profile.email;
      }
      if (client) {
        details.company = {
          company_id: client.id,
          name: client.name,
        };
      }
      window.Intercom('update', {
        app_id: import.meta.env.VITE_INTERCOM_APP_ID as string,
        ...details,
      });
    }
  };

  // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomshutdown
  static shutdown = (): void => {
    if (window.Intercom) window.Intercom('shutdown');
  };

  // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomshownewmessage
  static showNewMessage = (message = ''): void => {
    if (window.Intercom) window.Intercom('showNewMessage', message);
  };

  // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#intercomshow
  static show = (): void => {
    if (window.Intercom) window.Intercom('show');
  };

  static hideDefaultLauncher = (): void => {
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    }
  };

  static showDefaultLauncher = (): void => {
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
    }
  };

  static ping = (): void => {
    if (window.Intercom) window.Intercom('update');
  };
}
export default IntercomUtils;
