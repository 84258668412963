import { useCallback } from 'react';
import AuthService from '../services/AuthService';
import { usePbkAuth } from '../contexts/PbkAuthContext';
import useUnauthenticate from './useUnauthenticate';

const useRefreshToken = (externalLogoutFn?: () => void) => {
  const pbkAuth = usePbkAuth();
  const oldLogout = useUnauthenticate();

  return useCallback(
    async (secondTry?: boolean) => {
      let tokenRefreshed = false;

      try {
        const response = await AuthService.refreshToken(secondTry);
        if (!response?.meta?.success) {
          tokenRefreshed = false;
        } else {
          tokenRefreshed = true;
        }
      } catch {
        tokenRefreshed = false;
      }

      if (!tokenRefreshed) {
        if (externalLogoutFn) externalLogoutFn();
        else if (pbkAuth) pbkAuth.logout();
        else oldLogout();
      }

      return tokenRefreshed;
    },
    [externalLogoutFn, oldLogout, pbkAuth],
  );
};

export default useRefreshToken;
