import { initReactI18next } from 'react-i18next';
import i18next, { PostProcessorModule } from 'i18next';
import { systemDefaultLanguageCode } from './types/Languages';
import resourcesToBackend from 'i18next-resources-to-backend';

const debugging = { current: false };

const init = {
  postProcess: ['debugTranslations'],
  interpolation: { escapeValue: false }, // React already does escaping
  lng: systemDefaultLanguageCode,
  fallbackLng: 'en',
  react: {
    useSuspense: true,
  },
};

window.debugTranslations = () => {
  debugging.current = !debugging.current;
  i18next.reloadResources();
  i18next.init(init);
};

const debugTranslationsPlugin: PostProcessorModule = {
  type: 'postProcessor',
  name: 'debugTranslations',
  process: function (value, key, options) {
    if (!debugging.current) return value;

    const { lngs: _, lng: __, keyPrefix: ___, defaultValue: ____, interpolation: _____, ns, ...opts } = options;
    const optsStr = Object.keys(opts).length ? '#' + JSON.stringify(opts) : '';
    let keyStr = Array.isArray(key) ? key.join('.') : key;
    keyStr = keyStr.indexOf(':') === -1 ? keyStr : keyStr.split(':')[1];

    return `[🌍${ns}:${keyStr}${optsStr}]`;
  },
};

i18next
  .use(
    resourcesToBackend((language: string, namespace: string) => {
      if (namespace.startsWith('legal-')) {
        return import(`./translations/${language}/${namespace}.md?raw`).then((res) => ({ default: res }));
      }

      return import(`./translations/${language}/${namespace}.json`);
    }),
  )
  .use(initReactI18next)
  .use(debugTranslationsPlugin)
  .init(init);

export default i18next;
