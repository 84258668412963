// values must match "error" keys in common.json for translations
const ErrorType = {
  NOT_FOUND: 'not-found',
  UNAUTHORIZED: 'unauthorized',
  FORBIDDEN: 'forbidden',
  SERVER_ERROR: 'server-error',
  SERVICE_UNAVAILABLE: 'service-unavailable',
  AUTH_DOWN: 'auth-down',
  UNKNOWN_ERROR: 'unknown-error',
  APP_UPDATED: 'app-updated',
} as const;

export default ErrorType;
