import { DefaultValue, selector } from 'recoil';
import ErrorType from '../../models/Error';
import { ErrorStateProps, errorAtom } from '../atoms/Errors';

const { AUTH_DOWN, UNAUTHORIZED, FORBIDDEN, SERVER_ERROR, SERVICE_UNAVAILABLE, NOT_FOUND, UNKNOWN_ERROR } = ErrorType;

const errorStateSelector = selector<ErrorStateProps | null>({
  key: 'errorSelector',
  get: ({ get }) => get(errorAtom),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue || newValue === null) {
      set(errorAtom, newValue);
      return;
    }

    const value = { ...newValue };

    if (value.type) {
      if (([AUTH_DOWN, SERVER_ERROR, SERVICE_UNAVAILABLE, UNKNOWN_ERROR, NOT_FOUND] as string[]).includes(value.type)) {
        value.showRefresh = true;
        value.showContactSupport = true;
      } else if (([FORBIDDEN, UNAUTHORIZED] as string[]).includes(value.type)) {
        value.showGoBack = true;
      }
    }
    set(errorAtom, { ...value, pageUrl: window.location.pathname });
  },
});

export default errorStateSelector;
