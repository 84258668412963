import { ApiResponse } from '../models/ApiResponse';
import { NotificationResponse, NotificationPutRequest } from '../models/Notification';
import BaseService from './BaseService';

interface GetAllNotificationProps {
  pageSize?: number;
  pageNumber?: number;
  isRead?: boolean;
  clientId?: string;
}

export default class NotificationService extends BaseService {
  public static getNotifications(props: GetAllNotificationProps = {}): Promise<ApiResponse<NotificationResponse[]>> {
    return this.get('/v1/notifications', { params: props });
  }

  public static updateNotification(body: NotificationPutRequest): Promise<ApiResponse<null>> {
    return this.put(`/v1/notifications`, body);
  }

  public static markAllAsRead(clientId?: string): Promise<ApiResponse<boolean>> {
    return this.post('/v1/notifications/read-all', { clientId });
  }
}
