import { ReactElement, createContext, useCallback, useContext, useMemo, useState } from 'react';
import Toast from '../components/shared/Toast';
import { FCWithChildren } from '../types/FCWithChildren';
import { v4 as uuid } from 'uuid';

export enum ToastType {
  INFO,
  ERROR,
  SUCCESS,
  WARNING,
}

export type ToastDto = {
  type: ToastType;
  title: string | ReactElement;
  description?: string | ReactElement;
  expiresInMs?: number;
  slots?: {
    button?: ReactElement;
  };
};

export type ToastConfig = ToastDto & {
  id: string;
};

export type ToastContextType = {
  toasts: ToastConfig[];
  addToast: (toast: ToastDto) => void;
};

export const ToastContext = createContext<ToastContextType>({ toasts: [], addToast: () => console.warn('NO TOAST PROVIDER!') });
export const useToasts = (): ToastContextType => useContext(ToastContext);
export const ToastProvider: FCWithChildren = ({ children }) => {
  const [toasts, setToasts] = useState<ToastConfig[]>([]);

  const addToast = useCallback(
    (toast: ToastDto): void => {
      setToasts([...toasts, { ...toast, id: uuid() }]);
    },
    [toasts],
  );

  const removeToast = useCallback((id: string) => {
    setToasts((t) => t.filter((x) => x.id !== id));
  }, []);

  const providerValues = useMemo(() => ({ toasts, addToast }), [addToast, toasts]);

  return (
    <ToastContext.Provider value={providerValues}>
      <div className="z-toast fixed left-0 right-0 top-0 mx-auto mt-6 w-full max-w-lg md:left-auto md:right-4 md:mr-4 md:max-w-lg lg:max-w-lg">
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} removeToast={removeToast}>
            {toast.slots?.button && <Toast.Slot name="button">{toast.slots.button}</Toast.Slot>}
          </Toast>
        ))}
      </div>
      {children}
    </ToastContext.Provider>
  );
};
