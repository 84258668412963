import { FC } from 'react';
import { ariaAttributeProps, dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const XIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    // eslint-disable-next-line jsx-a11y/prefer-tag-over-role
    <svg
      data-cy="icon-x"
      {...dataAttributeProps(props)}
      {...ariaAttributeProps(props)}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      tabIndex={onClick ? 0 : undefined}
      className={`inline ${cursor} ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      data-testid="icon-x"
    >
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default XIcon;
