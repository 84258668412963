import { RegistrationFlowStatus, TwoFAActivateResponse, TwoFARegistrationResponse, UserProfile } from './../models/AuthModels';
import { ApiResponse } from '../models/ApiResponse';
import { AuthResponse, LoginResponse, TwoFAResponse, UserInvite } from '../models/AuthModels';
import BaseService from './BaseService';

class AuthService extends BaseService {
  public static login(username: string, password: string): Promise<ApiResponse<LoginResponse>> {
    return this.post('/v1/auth/login', { username, password });
  }

  public static logout(): Promise<ApiResponse<boolean>> {
    return this.post('/v1/auth/logout');
  }

  public static setPassword(password: string): Promise<ApiResponse<boolean>> {
    return this.post('/v1/auth/set-password', { credential: password });
  }

  public static resetPassword(password: string): Promise<ApiResponse<boolean>> {
    return this.post('/v1/auth/reset-password', { credential: password });
  }

  public static forgotPassword(email: string): Promise<ApiResponse<boolean>> {
    return this.post('/v1/auth/forgot-password', { email });
  }

  public static async refreshToken(secondTry?: boolean): Promise<ApiResponse<AuthResponse>> {
    return this.post('/v1/auth/token-refresh', null, { sent: secondTry, isTokenRefresh: true });
  }

  public static inviteUser(body: UserInvite): Promise<ApiResponse<boolean>> {
    return this.post('/v1/auth/invite', { ...body });
  }

  public static setUserDetails(body: Partial<UserProfile>): Promise<ApiResponse<LoginResponse>> {
    return this.post('/v1/auth/set-details', { ...body });
  }

  public static setup2FA(): Promise<ApiResponse<TwoFARegistrationResponse>> {
    return this.post('/v1/auth/two-factor/register');
  }

  public static activate2FA(pin: string): Promise<ApiResponse<TwoFAActivateResponse>> {
    return this.post('/v1/auth/two-factor/activate', { pin });
  }

  public static verify2FA(pin: string): Promise<ApiResponse<TwoFAResponse>> {
    return this.post('/v1/auth/two-factor/verify', { pin });
  }

  public static getRegistrationFlowStatus(): Promise<ApiResponse<RegistrationFlowStatus>> {
    return this.get('/v1/auth/register-status', { withCredentials: true });
  }

  public static async switchAccounts(accountId?: string, secondTry?: boolean): Promise<ApiResponse<AuthResponse>> {
    return this.post('/v1/auth/switch-account', { accountId: accountId }, { sent: secondTry, isTokenRefresh: true });
  }
}

export default AuthService;
