import { atom } from 'recoil';
import { ScriptexAuthStatus } from '../../models/AuthModels';
import User from '../../models/User';

const currentUserAtom = atom<User | null>({
  key: 'currentUserState',
  default: null,
});

const scriptexAuthStatusAtom = atom<ScriptexAuthStatus | null>({
  key: 'authStatusAtom',
  default: null,
});

export { currentUserAtom, scriptexAuthStatusAtom };
