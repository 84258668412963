import { atom } from 'recoil';
import { Client } from '../../models/Client';

const currentClientAtom = atom<Client | null>({
  key: 'currentClientId',
  default: null,
});

const currentTenantIdAtom = atom<string | null>({
  key: 'currentTenantId',
  default: null,
});

const hideClientsAtom = atom<boolean>({
  key: 'HideClients',
  default: false,
});

const myClientsAtom = atom<Client[]>({
  key: 'myClients',
  default: [],
});

export { currentClientAtom, hideClientsAtom, currentTenantIdAtom, myClientsAtom };
