/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { EventSystem } from '../events/EventSystem';
import { ApiResponse } from '../models/ApiResponse';
import { systemDefaultLanguageCode } from '../types/Languages';
import i18next from 'i18next';

const baseURL = import.meta.env.VITE_API_BASE_URL as string;

// eslint-disable-next-line no-console
const logger = import.meta.env.VITE_DEBUG_REQUESTS === 'true' ? console.debug : () => ({});

export default class BaseService {
  private static savedToken: string | null = null;
  private static currentClientId: string | null = null;
  private static currentTenantId: string | null = null;
  private static pendingRequests: Record<any, [AbortController, any]> = {};
  public static disableInterceptorErrors = false;
  public static httpClient: AxiosInstance = BaseService.recreateHttpClient();

  public static emptyResponse<T>(data: T | null = null): Promise<ApiResponse<T>> {
    return Promise.resolve<ApiResponse<T>>({
      data: data as T,
      hasNextPage: false,
      hasPreviousPage: false,
      totalCount: 0,
      totalPages: 0,
      status: 200,
      headers: null!,
      meta: {
        code: 200,
        message: '',
        success: true,
      },
    });
  }

  protected static async get<T>(url: string, config?: AxiosRequestConfig | undefined): Promise<ApiResponse<T>> {
    const res = await BaseService.makeRequest('GET', config, url);
    return BaseService.handleResponse<T>(res);
  }

  protected static async getRaw(url: string, config?: AxiosRequestConfig | undefined) {
    const res = await BaseService.makeRequest('GET', config, url);
    return res;
  }

  protected static async post<T>(url: string, data?: unknown, config?: AxiosRequestConfig | undefined): Promise<ApiResponse<T>> {
    const res = await BaseService.makeRequest('POST', config, url, data);
    return BaseService.handleResponse<T>(res);
  }

  protected static async postRaw(url: string, data?: unknown, config?: AxiosRequestConfig | undefined) {
    const res = await BaseService.makeRequest('POST', config, url, data);
    return res;
  }

  protected static async delete<T>(url: string, config?: AxiosRequestConfig | undefined): Promise<ApiResponse<T>> {
    const res = await BaseService.makeRequest('DELETE', config, url);
    return BaseService.handleResponse<T>(res);
  }

  protected static async head<T>(url: string, config?: AxiosRequestConfig | undefined): Promise<ApiResponse<T>> {
    const res = await BaseService.makeRequest('HEAD', config, url);
    return BaseService.handleResponse<T>(res);
  }

  protected static async options<T>(url: string, config?: AxiosRequestConfig | undefined): Promise<ApiResponse<T>> {
    const res = await BaseService.makeRequest('OPTIONS', config, url);
    return BaseService.handleResponse<T>(res);
  }

  protected static async patch<T>(url: string, data?: unknown, config?: AxiosRequestConfig | undefined): Promise<ApiResponse<T>> {
    const res = await BaseService.makeRequest('PATCH', config, url, data);
    return BaseService.handleResponse<T>(res);
  }

  protected static async put<T>(url: string, data?: unknown, config?: AxiosRequestConfig | undefined): Promise<ApiResponse<T>> {
    const res = await BaseService.makeRequest('PUT', config, url, data);
    return BaseService.handleResponse<T>(res);
  }

  protected static async postFile<T>(
    url: string,
    parameterName: string,
    file: File | File[],
    progresCb: (progress: number) => void,
  ): Promise<ApiResponse<T>> {
    const formData = new FormData();
    if (Array.isArray(file)) {
      file.forEach((f) => {
        formData.append(parameterName, f);
      });
    } else {
      formData.append(parameterName, file);
    }

    return this.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (event) => progresCb(event.total ? event.loaded / event.total : 0),
      signal: new AbortController().signal, // don't cancel multiple file requests
    });
  }

  private static handleResponse<T>(res: AxiosResponse): ApiResponse<T> {
    const response = {
      ...res.data,
      status: res.status,
      headers: res.headers,
    } as ApiResponse<T>;

    if (res.config.responseType === 'arraybuffer') {
      response.data = res.data;
    }

    if (response?.meta && !response?.meta?.success) {
      throw response;
    }

    // For some reason, the api sometimes returns an empty response - don't error on those
    if (!res.data) {
      return res.data;
    }

    return response;
  }

  private static makeRequest(requestMethod: string, config: AxiosRequestConfig | undefined, url: string, payload?: any) {
    config ??= {};

    // dev env
    let signiture: any;
    if (import.meta.hot) {
      signiture = BaseService.makeSigniture(requestMethod, url, { ...(payload || {}), ...(config.params || {}) });

      if (!config?.signal) {
        const controller = new AbortController();
        config = { ...config, signal: controller.signal };

        if (BaseService.pendingRequests[signiture]) {
          logger('Canceled 2nd request:', signiture.join('_'));
          controller.abort();
        } else {
          logger('Starting request:', signiture.join('_'));
          BaseService.pendingRequests[signiture] = [controller, null];
        }
      }
    }

    config.url = url;
    config.method = requestMethod;
    config.withCredentials = true;
    config.headers = {
      ...(config.headers || {}),
      'Accept-Language': i18next.language || systemDefaultLanguageCode,
      'x-user-language': i18next.language || systemDefaultLanguageCode,
    };
    if (payload) {
      config.data = payload;
    }

    const response = BaseService.httpClient.request(config).catch((e) => {
      if (import.meta.hot) {
        const existingRequest = BaseService.pendingRequests[signiture]?.[1];
        setTimeout(() => {
          delete BaseService.pendingRequests[signiture];
        }, 100);
        if (axios.isCancel(e)) {
          // try to resolve the duplicate request with the existing request's value
          return existingRequest ?? new Promise<any>(() => ({}));
        }
        logger('Ended request (err):', signiture.join('_'));
      }

      throw e;
    });

    if (import.meta.hot && BaseService.pendingRequests[signiture]) {
      BaseService.pendingRequests[signiture][1] ??= response;
    }

    // dev env
    if (import.meta.hot) {
      response.then(() => {
        logger('Ended request (okay):', signiture.join('_'));
        setTimeout(() => {
          delete BaseService.pendingRequests[signiture];
        }, 100);
      });
    }

    return response;
  }

  private static makeSigniture(method: string, url: string, payload: any): any[] {
    const caller =
      new Error().stack
        ?.split('\n')
        .find((x) => x.includes('at') && !x.includes('Service'))
        ?.trim()
        .split(' ')[1] || '';
    const result: any[] = [caller, method, url];

    if (!payload) {
      return result;
    }

    if (Array.isArray(payload) || typeof payload !== 'object') {
      result.push(payload);
    } else {
      const objectSerialize = (obj: Record<string, any>) => {
        const keyValues = Object.entries(obj).sort(([keyA, _], [keyB, __]) => keyA.localeCompare(keyB));
        if (!Array.isArray(keyValues[1]) && typeof keyValues[1] === 'object') {
          result.push(keyValues[0]);
          objectSerialize(keyValues[1]);
        } else {
          result.push(keyValues);
        }
      };
      objectSerialize(payload);
    }

    return result.flat();
  }

  // Used for example when token changes
  public static recreateHttpClient(token: string | null = null): AxiosInstance {
    // Both arguments won't always be passed in, so save them for future calls
    BaseService.savedToken = token || BaseService.savedToken;

    BaseService.httpClient = axios.create({
      baseURL,
      withCredentials: !BaseService.savedToken,
      headers: {
        Authorization: BaseService.savedToken ? `Bearer ${BaseService.savedToken}` : undefined,
        'x-client-id': BaseService.currentClientId || undefined,
        'x-tenant-id': BaseService.currentTenantId || undefined,
      },
      validateStatus: () => true, // never throw errors if not 200 response - we do that manually
      paramsSerializer: (params) => {
        // Used because the backend expects arrays in query strings to be in format:
        // key=value1&key=value2&key=value3
        // While the default for axios is
        // key[]=value1&key[]=value2&key[]=value3
        return qs.stringify(params, { indices: false, arrayFormat: 'repeat', allowDots: true });
      },
    });

    EventSystem.fireEvent('http-client-recreated', BaseService.httpClient);
    return BaseService.httpClient;
  }

  public static setClientTenantDetails(clientId: string, tenantId: string) {
    BaseService.currentClientId = clientId;
    BaseService.currentTenantId = tenantId;
    BaseService.recreateHttpClient();
  }

  public static unsetClientTenantDetails() {
    BaseService.currentClientId = null;
    BaseService.currentTenantId = null;
  }

  public static clearToken(): void {
    BaseService.savedToken = null;
    BaseService.recreateHttpClient(null);
  }
}
