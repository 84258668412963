import { createElement } from 'react';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { dataAttributeProps } from '../../../utils/ComponentUtils';

export enum HeadingSize {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  CUSTOM = 'span',
}

type HeadingProps = {
  size?: HeadingSize;
  actualSize?: HeadingSize; // used if you want to use a different size than the enum for a11y structure purposes
  className?: string;
  textColor?: string;
  onClick?: () => void;
  id?: string;
};

const fontSizes = {
  [HeadingSize.H1]: 'text-dpm-48',
  [HeadingSize.H2]: 'text-dpm-40',
  [HeadingSize.H3]: 'text-dpm-32',
  [HeadingSize.H4]: 'text-dpm-25',
  [HeadingSize.H5]: 'text-dpm-20',
  [HeadingSize.H6]: 'text-dpm-16',
  [HeadingSize.CUSTOM]: '',
};

export const Heading: FCWithChildren<HeadingProps> = (props) => {
  const { children, size = HeadingSize.H2, className, textColor, onClick, id, actualSize } = props;
  const fontSize = fontSizes[actualSize || size];
  // Default color if none provided
  const color = textColor || 'text-primary-1';
  return createElement(
    size,
    {
      id,
      onClick,
      className: `${fontSize} ${color} ${onClick !== undefined ? 'cursor-pointer' : ''} ${className || ''}`,
      'data-testid': `heading-${size}`,
      'data-cy': `heading-${size}`,
      ...dataAttributeProps(props),
    },
    children,
  );
};
