/* eslint-disable @typescript-eslint/no-explicit-any */
import { Children, PropsWithChildren, ReactElement } from 'react';

const useSlot = <TProps extends PropsWithChildren>(
  props: TProps,
  name: TProps extends { _slots: infer TSlot } ? TSlot : never,
  fallback?: ReactElement<any, any> | null,
): (() => JSX.Element | null) => {
  const childrenArr: any[] = Children.toArray(props.children);
  const predicate = (child: any) => {
    return child?.props?.name === name;
  };

  if (childrenArr.some(predicate)) {
    return () => childrenArr.find(predicate)?.props?.children;
  }

  if (fallback) {
    return () => fallback;
  }

  function EmptyPlaceholder() {
    return null;
  }
  return EmptyPlaceholder;
};

export default useSlot;
