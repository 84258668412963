import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FCWithChildren } from '../types/FCWithChildren';
import FAConfig from '../FAConfig';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { scriptexAuthStatusAtom } from '../recoil/atoms/Auth';
import useRefreshToken from '../hooks/useRefreshToken';
import { FusionAuthProvider, useFusionAuth } from '@fusionauth/react-sdk';
import useUnauthenticate from '../hooks/useUnauthenticate';

type AuthContextType = {
  mode: 'fusion' | 'scriptex';
  isAuthenticated: boolean;
  isPending: boolean;

  requireSignIn: () => void;
  refreshToken: () => Promise<void>;
  logout: () => void;

  features: {
    switchingAccounts: boolean;
    unmanagedTokens: boolean;
    clientHeader: boolean;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _implementationData: Record<string, any>;
};

const AuthContext = createContext<AuthContextType>(null!);
export const usePbkAuth = (): AuthContextType => useContext(AuthContext);

export const PbkAuth: FCWithChildren = ({ children }) => {
  const [_, setParams] = useSearchParams();
  const navigate = useNavigate();

  const signinCallback = useCallback(
    (state: string | undefined) => {
      setParams(
        (prev) => {
          prev.delete('locale');
          prev.delete('state');
          prev.delete('userState');
          return prev;
        },
        { replace: true },
      );

      const { returnUrl } = JSON.parse(state || '{}');
      if (returnUrl) {
        navigate(returnUrl, { replace: true });
      }
    },
    [navigate, setParams],
  );

  return import.meta.env.VITE_USE_FUSIONAUTH === 'true' ? (
    <FusionAuthProvider {...FAConfig} onRedirect={signinCallback}>
      <FusionAuthPbkAuthContextProvider>{children}</FusionAuthPbkAuthContextProvider>
    </FusionAuthProvider>
  ) : (
    <ScriptexPbkAuthContextProvider>{children}</ScriptexPbkAuthContextProvider>
  );
};

const FusionAuthPbkAuthContextProvider: FCWithChildren = ({ children }) => {
  const faAuth = useFusionAuth();
  const location = useLocation();

  const faIsAuthenticated = useMemo(() => {
    return faAuth.isLoggedIn;
  }, [faAuth.isLoggedIn]);

  const faRequireSignin = useCallback(() => {
    const state = JSON.stringify({ returnUrl: location.pathname });
    faAuth.startLogin(state);
  }, [faAuth, location.pathname]);

  const faDefaultAccountId = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (faAuth.userInfo as any).defaultAccountId;
  }, [faAuth.userInfo]);

  const faLogout = useCallback(() => {
    faAuth.startLogout();
  }, [faAuth]);

  const faRefreshToken = useCallback(() => {
    if (!faIsAuthenticated) {
      faRequireSignin();
    }

    return faAuth
      .refreshToken()
      .then(() => {
        // NOOP
      })
      .catch(() => faLogout());
  }, [faAuth, faLogout, faIsAuthenticated, faRequireSignin]);

  const value = useMemo<AuthContextType>(
    () => ({
      mode: 'fusion',
      isAuthenticated: faIsAuthenticated,
      isPending: faAuth.isFetchingUserInfo,
      requireSignIn: faRequireSignin,
      refreshToken: faRefreshToken,
      logout: faLogout,
      features: {
        switchingAccounts: false,
        unmanagedTokens: false,
        clientHeader: true,
      },
      _implementationData: {
        defaultAccountId: faDefaultAccountId,
      },
    }),
    [faIsAuthenticated, faAuth.isFetchingUserInfo, faRequireSignin, faRefreshToken, faLogout, faDefaultAccountId],
  );

  // Auto-refresh tokens
  useEffect(() => {
    const timer = setInterval(() => {
      // This method does nothting if the token isn't about to expire,
      // so it's safe to constantly call
      faRefreshToken();
    }, 60 * 1000); // attempt every minute

    return () => clearInterval(timer);
  }, [faRefreshToken]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const ScriptexPbkAuthContextProvider: FCWithChildren = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useUnauthenticate();
  const [pendingInitialRefresh, setPendingInitialRefresh] = useState(true);

  const [scriptexAuthStatus, setScriptexAuthStatus] = useRecoilState(scriptexAuthStatusAtom);

  const sxLogout = useCallback(() => {
    logout();
    setScriptexAuthStatus({ isAuthenticated: false });
  }, [logout, setScriptexAuthStatus]);

  const refreshToken = useRefreshToken(sxLogout);

  const sxRequireSignin = useCallback(() => {
    navigate(`/auth/login?return=${location.pathname}`, { replace: true });
  }, [location.pathname, navigate]);

  const sxRefreshToken = useCallback(() => {
    return refreshToken().then((success) => {
      setScriptexAuthStatus({ isAuthenticated: success });
      setPendingInitialRefresh(false);
    });
  }, [refreshToken, setScriptexAuthStatus]);

  const value = useMemo<AuthContextType>(
    () => ({
      mode: 'scriptex',
      isAuthenticated: scriptexAuthStatus?.isAuthenticated ?? false,
      isPending: pendingInitialRefresh,
      requireSignIn: sxRequireSignin,
      refreshToken: sxRefreshToken,
      logout: sxLogout,
      features: {
        switchingAccounts: true,
        unmanagedTokens: true,
        clientHeader: false,
      },
      _implementationData: {
        setAuthStatus: setScriptexAuthStatus,
      },
    }),
    [pendingInitialRefresh, scriptexAuthStatus?.isAuthenticated, setScriptexAuthStatus, sxLogout, sxRefreshToken, sxRequireSignin],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
