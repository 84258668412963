import { AxiosInstance } from 'axios';
import { SupportedLanguage } from '../types/Languages';
import { ActionHighlightInfo, AdHocAnswerResponse, AnswerResponse } from './../models/Form';
import FormAnswerEvent, { AdHocFormAnswerEvent } from './FormAnswerEvent';
import { FormSectionValidEvent } from './FormSectionValidEvent';
import { FormValidEvent } from './FormValidEvent';
import { OpenFormSectionEvent } from './OpenFormSectionEvent';
import PicklistUpdatedEvent from './PicklistUpdatedEvent';
import { ProgressUpdatedEvent } from './ProgressUpdatedEvent';
import CommentEvent from './QuestionCommentEvent';
import { QuestionRequirednessChanged } from './QuestionRequirednessChanged';
import { QuestionVisibilityChanged } from './QuestionVisibilityChanged';
import ResourcePicklistUpdatedEvent from './ResourcePicklistUpdatedEvent';
import { ActionTypeNames } from '../components/form/ActionTypes';
import ClientFormVersionHistory from '../models/ClientFormVersionHistory';
import QuestionRiskEvent from './QuestionRiskEvent';
import QuestionRiskCreatedEvent from './QuestionRiskCreatedEvent';
import QuestionRiskEditEvent from './QuestionRiskEditEvent';
import QuestionRiskViewEvent from './QuestionRiskViewEvent';
import { MenuAction } from '../components/shared/placeholder/PlaceholderSelectMenu';
import { DistributionMember, DistributionResponse } from '../models/Distribution';

export interface Events {
  'before-form-answer': FormAnswerEvent;
  'before-ad-hoc-form-answer': AdHocFormAnswerEvent;
  'form-valid': FormValidEvent;
  'form-section-valid': FormSectionValidEvent;
  'form-progress-updated': ProgressUpdatedEvent;
  'picklist-updated': PicklistUpdatedEvent;
  'resource-picklist-updated': ResourcePicklistUpdatedEvent;
  'associations-updated': null;
  'question-comment-new': CommentEvent;
  'question-comment-open': CommentEvent;
  'comment-thread-updated': { commentId: string };
  'comment-cancel': null;
  'question-visibility-changed': QuestionVisibilityChanged;
  'question-requiredness-changed': QuestionRequirednessChanged;
  'form-answer': AnswerResponse & { type: ActionTypeNames; sectionId: string };
  'form-remove-ad-hoc-answer': Record<string, AdHocAnswerResponse[]>;
  'form-ad-hoc-answer': { adHocAnswers: Record<string, AdHocAnswerResponse[]>; actionId: string; fieldId: string };
  'language-changed': SupportedLanguage;
  'http-client-recreated': AxiosInstance;
  'data-import-done': null;
  'data-export-done': null;
  'open-form-section': OpenFormSectionEvent;
  'dynamic-data-popup-opened': { action: MenuAction };
  'dynamic-data-popup-closed': { action: MenuAction; createdPlaceholder: boolean };
  'user-state-histories-loaded': { clientFormId: string; versions: ClientFormVersionHistory[] };
  'highlight-question': ActionHighlightInfo | null;
  'question-risk-new': QuestionRiskEvent;
  'question-risk-created': QuestionRiskCreatedEvent;
  'question-risk-edit': QuestionRiskEditEvent;
  'question-risk-view': QuestionRiskViewEvent;
  'highlight-risk': { riskIds: string[] };
  'form-ad-hoc-sorting': { actionId: string; fieldIds: string[] };
  'distribution-updated': DistributionResponse;
  'distribution-member-reminded': DistributionMember;
  acknowledged: { id: string; signed: boolean };
}

export class EventSystem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static eventQueues: Record<string, ((event: any) => void)[]> = {};

  public static listen<T extends keyof Events>(event: T, listener: (event: Events[T]) => void): void {
    if (this.eventQueues[event] === undefined) {
      this.eventQueues[event] = [];
    }
    this.eventQueues[event].push(listener);
  }

  public static stopListening<T extends keyof Events>(event: T, listener: (event: Events[T]) => void): void {
    if (this.eventQueues[event] === undefined) {
      return;
    }
    this.eventQueues[event] = this.eventQueues[event].filter((x) => x !== listener);
  }

  public static fireEvent<T extends keyof Events>(event: T, data: Events[T]): void {
    if (this.eventQueues[event] === undefined) {
      return;
    }

    if (import.meta.hot) {
      // eslint-disable-next-line no-console
      console.debug(
        new Error().stack?.split('\n')[2].trim().split(' ')[1],
        'fired event',
        event,
        'to',
        this.eventQueues[event].length,
        'listeners. data:',
        data,
      );
    }

    for (const listener of this.eventQueues[event]) {
      listener(data);
    }
  }
}
