import { useEffect, useState } from 'react';

export const useWindowFocused: () => boolean = () => {
  const [focused, setFocused] = useState(() => !document.hidden);

  useEffect(() => {
    const handler = () => {
      setFocused(!document.hidden);
    };

    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  }, []);
  return focused;
};
